<template>
  <div>
    <div v-if="loader" class="text-center">
      <Loader
        :size="'big'"
      />
    </div>
    <div v-else-if="pixel">
      <!-- HEADER -->
      <div class="d-flex justify-content-between align-items-center mb-4">
        <div>
          <h1 class="highlighted-color1">
            <span>{{ pixel.name }}</span>
          </h1>
        </div>
        <div>
          <Button
            class="btn btn-sm btn-primary"
            v-b-modal="'upsert-pixel-modal'"
          >
            Editar pixel
          </Button>
          <UpsertPixelModal
            :advertiser="pixel.advertiser"
            :pixel="pixel"
            @pixel-updated="getPixel"
          />
        </div>
      </div> <!-- END HEADER -->
      <!-- MESSAGES -->
        <div
          v-if="pixel.status === 'ERROR'"
        >
          <div
            class="mb-4 error-message-card text-white font-size-small p-3"
            v-if="pixel.status === 'ERROR'"
          >
            <p
              class="mb-0"
            >
              Ops, houve um erro na criação do pixel. Clique aqui para tentar de novo.
              <span>
                <RetryPixelCreationOnPlatformButton
                  :pixelId="pixel.id"
                  @retry-successful="getPixel"
                  @retry-error="handleRetryError"
                  color="white"
                />
              </span>
            </p>
          </div>
        </div> <!-- END MESSAGES -->

      <!-- PIXEL CARD -->
      <div class="card mb-4">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <div>
              <p class="mb-0">
                Anunciante: {{ pixel.advertiser.name }}<br/>
                Data de criação: {{ pixel.created_at | formatDate }}<br/>
                <span>
                  Status: {{ pixel.status | formatStatus }}
                  <template v-if="pixel.status === 'ERROR'">
                    <RetryPixelCreationOnPlatformButton
                      :pixelId="pixel.id"
                      @retry-successful="getPixel"
                      @retry-error="handleRetryError"
                    />
                  </template>
                </span>
              </p>
            </div>
            <div class="text-lg-end">
            </div>
          </div>
        </div>
      </div> <!-- END PIXEL CARD -->

      <!-- PIXEL RULES TABLE -->
      <div>
        <h2 class="highlighted-color1 mb-4">
          <span>Regras</span>
        </h2>
        <PixelRuleListTable
          :rules="pixel.rules"
          platform="GOOGLE"
          :advertiser="pixel.advertiser"
        />
      </div> <!-- END PIXEL RULES TABLE -->
    </div>
  </div>
</template>

<script>

import PixelsService from '@/modules/pixels/services/pixels-service'

import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'
import PixelRuleListTable from '@/components/pixels/PixelRuleListTable'
import RetryPixelCreationOnPlatformButton from '@/components/pixels/RetryPixelCreationOnPlatformButton'
import UpsertPixelModal from '@/components/pixels/UpsertPixelModal'

export default {
  components: {
    Button,
    Loader,
    PixelRuleListTable,
    RetryPixelCreationOnPlatformButton,
    UpsertPixelModal
  },
  data () {
    return {
      pixel: null,
      loader: false
    }
  },
  metaInfo () {
    return {
      title: this.pixel ? this.pixel.name : ''
    }
  },
  created () {
    this.getPixel()
  },
  methods: {
    /**
     * Get pixel
     */
    async getPixel () {
      this.loader = true
      try {
        const pixel = await PixelsService.getPixel(this.$route.params.id)
        this.pixel = pixel
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    },
    /**
     * Handle retry error
     */
    handleRetryError (error) {
      error.handleGlobally && error.handleGlobally()
    }
  }
}
</script>
