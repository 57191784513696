<template>
  <b-modal
    :id="modalId"
    :modal-class="'default-modal'"
    :hide-footer="true"
  >
    <div class="text-center">
      <h2 class="highlighted-color1">
        <span>{{ rule ? rule.name : '' }}</span>
      </h2>
    </div>
    <div class="mt-4" v-if="rule && advertiser">
      <!-- YAHOO -->
      <template v-if="platform === 'YAHOO'">
        <h3>Instruções</h3>
        <p>Place this tag on the page of your website you want to track.</p>
        <ul>
          <li>Copy and paste the below code inside the &lt;head&gt; tag, on all pages of your website you want to track.</li>
          <li>To verify that the tag is working correctly, go to the Tag List view and you should see the number of users who visited your page.</li>
          <li>You can now start using your Conversion/Retargeting rules in your Campaigns.</li>
        </ul>
        <code class="mb-4">
          &lt;script type="application/javascript"&gt;(function(w,d,t,r,u){w[u]=w[u]||[];w[u].push({'projectId':'10000','properties':{'pixelId':'{{rule.pixel.yahoo_id}}','he': '&lt;email_address&gt;','auid': '&lt;sha256_hashed_user_id&gt;'}});var s=d.createElement(t);s.src=r;s.async=true;s.onload=s.onreadystatechange=function(){var y,rs=this.readyState,c=w[u];if(rs&&rs!="complete"&&rs!="loaded"){return}try{y=YAHOO.ywa.I13N.fireBeacon;w[u]=[];w[u].push=function(p){y([p])};y(c)}catch(e){}};var scr=d.getElementsByTagName(t)[0],par=scr.parentNode;par.insertBefore(s,scr)})(window,document,"script","https://s.yimg.com/wi/ytc.js","dotq");&lt;/script&gt;
        </code>
        <p>Place this tag on the page of your website you want to track.</p>
        <ul>
          <li>Copy and paste the below code inside the &lt;head&gt; tag, on the page ou action (button, form) of your website you want to track.</li>
          <li>Replace the {INSERT_MACRO_HERE} placeholder value in this tag</li>
          <li>To verify that the tag is working correctly, go to the Tag List view and you should see the number of users who visited your page.</li>
          <li>You can now start using your Conversion/Retargeting rules in your Campaigns.</li>
        </ul>
        <code>
          &lt;img src="https://sp.analytics.yahoo.com/spp.pl?a=10000&.yp={{ rule.pixel.yahoo_id }}&he={{ rule.event_name }}&auid={{ rule.event_name }}&ea={{ rule.event_name }}"/&gt;
        </code>
      </template>
      <!-- GOOGLE -->
      <template v-else-if="platform === 'GOOGLE'">
        <h3>Instruções de inserção via GTM ou Wix</h3>
        <p>Para inserção via Google Tag Manager, ou caso você tenha um site desenvolvido no Wix, <a href="/help-center/entry/como-instalar-um-pixel-com-gtm-google-tag-manager-usando-floodlight-codigos" target="_blank">clique aqui</a> para acessar o nosso guia e siga as instruções fornecidas utilizando os dados abaixo quando necessário.</p>
        <p>
          <strong>Floodlight ID:</strong> {{ rule.pixel.google_id }}<br/>
          <strong>Activity Group Tag String:</strong> invmedia<br/>
          <strong>Activity Tag String:</strong> {{ rule.google_activity_tag }}
        </p>
        <h3>Instruções de inserção manual</h3>
        <p>Essa tag deve ser inserida entre as tags &lt;body&gt; e &lt;/body&gt;, o mais próximo possível da tag de abertura.</p>
        <p>Para inserção manual, <a href="/help-center/entry/como-instalar-um-pixel-com-gtm-google-tag-manager-usando-html" target="_blank">clique aqui</a> para acessar o nosso guia e siga as instruções fornecidas utilizando os dados abaixo quando necessário.</p>
        <code class="mb-3">
          &lt;script type="text/javascript"&gt;
          var axel = Math.random() + "";
          var a = axel * 10000000000000;
          document.write('&lt;iframe src="https://{{ rule.pixel.google_id }}.fls.doubleclick.net/activityi;src={{ rule.pixel.google_id }};type=invmedia;cat={{ rule.google_activity_tag }};dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=' + a + '?" width="1" height="1" frameborder="0" style="display:none"&gt;&lt;/iframe&gt;');
          &lt;/script&gt;
          &lt;noscript&gt;
          &lt;iframe src="https://{{ rule.pixel.google_id }}.fls.doubleclick.net/activityi;src={{ rule.pixel.google_id }};type=invmedia;cat={{ rule.google_activity_tag }};dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" frameborder="0" style="display:none"&gt;&lt;/iframe&gt;
          &lt;/noscript&gt;

        </code>
      </template> <!-- END GOOGLE -->
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'PixelCodeModal',
  props: ['advertiser', 'pixel', 'platform', 'rule'],
  computed: {
    modalId () {
      if (this.rule) return 'pixel-rule-code-modal'
      else return 'pixel-rule-code-modal'
    }
  }
}
</script>

<style lang="scss" scoped>
code {
  background-color: #f1f1f1;
  display: block;
  padding: 15px;
}
</style>
