<template>
  <div class="pixel-rule-list-table">
    <div v-if="loader && localPixelRules.length === 0" class="text-center">
      <Loader
        :size="'big'"
      />
    </div>
    <template v-else>
      <!-- CARD -->
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr class="text-nowrap">
                  <th>Nome do pixel</th>
                  <th>Nome da regra</th>
                  <th>Data de criação</th>
                  <th>Objetivo</th>
                  <th>Nome do evento</th>
                  <th>Status</th>
                  <th>Código do pixel</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(rule, index) in localPixelRules" :key="index"  class="text-nowrap">
                  <td>
                    <router-link :to="{ name: 'pixels.show', params: { id: rule.pixel.id } }" class="link-color3">
                      {{ rule.pixel.name }}
                    </router-link>
                  </td>
                  <td>{{ rule.name }}</td>
                  <td>{{ rule.created_at | formatDate }}</td>
                  <td>{{ rule.objective | formatObjective }}</td>
                  <td>{{ rule.event_name }}</td>
                  <td>
                    {{ rule.status | formatStatus }}
                  </td>
                  <td>
                    <template v-if="platform">
                      <a
                        href="#"
                        @click.prevent="openPixelRuleCodeModal(rule.pixel, rule)"
                        class="link-color2"
                        :class="{
                          'pending': rule.pixel.status === 'PENDING'
                        }"
                        :id="`rule-${rule.id}`"
                      >
                        <i class="fa fa-code"></i>
                      </a>
                      <b-tooltip
                        :target="`rule-${rule.id}`"
                        :title="`${rule.status === 'PENDING' ? 'A criação do pixel pode levar até 48 horas' : 'Copiar código'}`"
                        container="body"
                        v-if="rule.status === 'PENDING'"
                      ></b-tooltip>
                    </template>
                    <template v-else>
                      <a
                        href="#"
                        @click.prevent=""
                        class="link-color2"
                        :class="{
                          'pending': true
                        }"
                        :id="`rule-${rule.id}`"
                      >
                        <i class="fa fa-code"></i>
                      </a>
                      <b-tooltip
                        :target="`rule-${rule.id}`"
                        :title="`${rule.status === 'PENDING' ? 'A criação do pixel pode levar até 48 horas' : 'Para ter acesso ao código do pixel, acesse diretamente a página da campanha associada.'}`"
                        container="body"
                      ></b-tooltip>
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
            <PixelRuleCodeModal
              :pixel="selectedPixel"
              :platform="platform"
              :rule="selectedPixelRule"
              :advertiser="advertiser"
            />
          </div>
          <div class="text-center mt-4">
            <Button
              class="btn-primary btn-sm"
              :loading="loader"
              @click="loadMore"
              v-if="isLoadMoreVisible"
            >
              Carregar mais
            </Button>
          </div>
        </div>
      </div> <!-- END CARD -->
    </template>
  </div>
</template>

<script>

// import PixelsService from '@/modules/pixels/services/pixels-service'

import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'
import PixelRuleCodeModal from '@/components/pixels/PixelRuleCodeModal'

export default {
  props: ['advertiser', 'rules', 'platform'],
  name: 'PixelRuleListTable',
  components: {
    Button,
    Loader,
    PixelRuleCodeModal
  },
  data () {
    return {
      isLoadMoreVisible: false,
      loader: false,
      localPixelRules: [],
      page: 1,
      selectedPixel: null,
      selectedPixelRule: null
    }
  },
  created () {
    if (this.rules) this.localPixelRules = this.rules
  },
  methods: {
    /**
     * Open pixel rule code modal
     */
    openPixelRuleCodeModal (pixel, rule) {
      if (rule.status === 'PENDING') return
      this.selectedPixel = pixel
      this.selectedPixelRule = rule
      this.$bvModal.show('pixel-rule-code-modal')
    }
  }
}
</script>

<style lang="scss" scoped>
a.pending {
  opacity: 0.5;
}
</style>
